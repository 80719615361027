import { __dashCase } from '@lotsof/sugar/string'
import { type TImage, type TImgix } from '../image.type.js'

export default function generateUrl(
  image: TImage,
  width?: number,
  height?: number,
): string {
  const params: string[] = []
  const imgixParams: TImgix = {
    auto: 'format',
    crop: 'focalpoint',
    q: 75,
  }

  if (image.focalPoint) {
    imgixParams.fpX = image.focalPoint.x
    imgixParams.fpY = image.focalPoint.y
    imgixParams.fit = 'crop'
  }
  if (image.imgix?.ar) {
    imgixParams.fit = 'crop'
  }

  if (width) {
    imgixParams.w = width
  }
  if (height) {
    imgixParams.h = height
  }

  for (let [key, value] of Object.entries({
    ...imgixParams,
    ...(image.imgix ?? {}),
  })) {
    params.push(`${__dashCase(key)}=${value}`)
  }

  const src = image.url ?? image.src
  return `${image.url ?? image.src}${src?.includes('?') ? '&' : '?'}${params.join('&')}`
}
